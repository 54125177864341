<template>
  <modal
    :modalId="`modalShow`"
    :modalTitle="`Banner: ${param.name}`"
    :load="false"
    :typeLg="`modal_lg`"
    @closeModal="closeModal"
  >
    <div class="col-md-12 mb-1 form-group form-outline">
      <div
        :class="param.type == 0 ? 'm-auto div-image-show-modal' : 'm-auto div-image-show-dashboard'"
        :style="{ 'background-image': 'url(' + getImg(param.img) + ')' }"
      ></div>
    </div>
  </modal>
</template>

<script>
export default {
  components: {
    modal: () => import("@/components/modal/modalDefault.vue")
  },
  props: ["param"],
  methods: {
    closeModal() {
      this.$emit("closeModal", "modalShow");
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.div-image-show-modal {
  max-width: 700px !important;
  height: 500px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: flex;
}
.div-image-show-dashboard {
  max-width: 960px !important;
  height: 105px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: flex;
}
</style>
